import { Component, DestroyRef, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  createFormGroup,
  getAircraftManufacturerOptions,
  getICAOByCountryOptions,
  getStaticContent,
  getTailNumberByCountryOptions,
  IQuestionSection,
  IReferenceOption,
  noEmptyProps,
  STATIC_CONTENT_CONTEXT,
  STATIC_CONTENT_PAYLOAD
} from '@inmarsat-itcloudservices/ui';
import { asyncScheduler, BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { first, observeOn, share, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SiteFormService } from '@app/account/services/site-form.service';
import { AIRCRAFT_CLASS_VALIDATION_EXCLUDE_LIST, ICAO_PATTERN, staticContent } from '@app/app.constants';
import { flatten } from 'ramda';
import { IState } from '@app/shared-store';
import { Store } from '@ngrx/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ISiteConfig, ISiteDetail, SiteCategory } from '../../models/site.model';

const MARKET_LABEL = getStaticContent('accounts.create.site.vessel_information.vessel_market', staticContent);
const SECTOR_LABEL = getStaticContent('accounts.create.site.vessel_information.vessel_sector', staticContent);
const CLASS_LABEL = getStaticContent('accounts.create.site.aircraft_information.aircraft_class', staticContent);
const MARKET_PLACEHOLDER = getStaticContent(
  'accounts.create.site.vessel_information.select_vessel_market',
  staticContent
);
const SECTOR_PLACEHOLDER = getStaticContent(
  'accounts.create.site.vessel_information.select_vessel_sector',
  staticContent
);
const CLASS_PLACEHOLDER = getStaticContent(
  'accounts.create.site.aircraft_information.select_aircraft_class',
  staticContent
);

@Component({
  selector: 'inm-site-form',
  templateUrl: './site-form.component.html',
  styleUrls: ['./site-form.component.scss'],
  providers: [
    {
      provide: STATIC_CONTENT_CONTEXT,
      useValue: 'site_form'
    },
    {
      provide: STATIC_CONTENT_PAYLOAD,
      useValue: staticContent
    }
  ]
})
export class SiteFormComponent implements OnInit, OnDestroy {
  @Input() public set siteDetails(value: ISiteDetail) {
    if (value) {
      this.primaryForm.reset();
      this.siteForm.isEditingSite = true;
      this.isEditing = true;
      const model = this.siteForm.mapSiteToFormModel(value);
      this.primaryForm.patchValue(model.primary);

      // Site type cannot be changed for existing sites.
      this.typeCtrl.disable();
      this.siteType$.next(value.type);
      this.siteCategory$.next(model.primary.category);
      this.getAircraftManufacturers();

      this.categoryOptions$.pipe(first()).subscribe(() => {
        if (this.secondaryForm) {
          this.countrySelected = model.secondary.country;
          if (model.primary.type === SiteCategory.Air) {
            model.secondary['aircraftManufacturer'] = model.secondary['aircraftManufacturer']
              ? this.aircraftManufacturers.find(
                  (aircraftManufacturer) => aircraftManufacturer.label === model.secondary['aircraftManufacturer']
                )?.label
              : null;
          }
          this.secondaryForm.patchValue(model.secondary);
        }
        this.form.markAsPristine();
      });
    }
  }

  @Input()
  public accountNumber: string;

  @Input()
  public siteName: string;

  public siteTypes = Object.values(SiteCategory);

  public secondaryQuestions: IQuestionSection[];

  public categoryOptions$: Observable<IReferenceOption[]>;

  public subcategoryOptions$: Observable<IReferenceOption[]>;

  public categoryLabel: string;

  public subcategoryLabel: string;

  public categoryPlaceholder: string;

  public subcategoryPlaceholder: string;

  public isEditing: boolean;

  public readonly siteNameCtrl = new UntypedFormControl(null, []);

  public readonly typeCtrl = new UntypedFormControl(SiteCategory.Sea, Validators.required);

  public readonly categoryCtrl = new UntypedFormControl(null, Validators.required);

  public readonly subcategoryCtrl = new UntypedFormControl(null, Validators.required);

  public disableAccountName = true;

  public primaryForm = new UntypedFormGroup({
    name: this.siteNameCtrl,
    type: this.typeCtrl,
    category: this.categoryCtrl,
    subcategory: this.subcategoryCtrl
  });

  public secondaryForm: UntypedFormGroup;

  public form = new UntypedFormGroup({
    primary: this.primaryForm
  });

  private countrySelected: string;

  private airCategory: string;

  private icaoBinaryValidation: { label: string; value: string };

  private tailPrefix: { label: string; value: string };

  private aircraftManufacturers: { label: string; value: string }[] = [];

  private readonly siteType$ = new BehaviorSubject<SiteCategory>(SiteCategory.Sea);

  private readonly siteCategory$ = new ReplaySubject<string>(1);

  private readonly destroyRef = inject(DestroyRef);

  private readonly subs = new Subscription();

  constructor(
    private readonly siteForm: SiteFormService,
    private readonly store: Store<IState>,
    public router: Router
  ) {
    this.configureCategoryControl();
    this.subcategoryOptions$ = this.siteCategory$.pipe(
      observeOn(asyncScheduler),
      switchMap((category) =>
        this.typeCtrl.value === SiteCategory.Sea && category ? this.siteForm.getVesselSubcategories(category) : of(null)
      ),
      tap((options: IReferenceOption[]) => {
        this.subcategoryCtrl.reset({
          disabled: options === null,
          value:
            options && options.some(({ value }) => value === this.subcategoryCtrl.value)
              ? this.subcategoryCtrl.value
              : null
        });
      })
    );
    this.categoryCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((category) => {
      this.siteCategory$.next(category);
    });
  }

  public ngOnInit(): void {
    if (!this.isEditing) {
      this.siteNameCtrl.setValue(this.siteName);
      this.siteForm.isEditingSite = false;
    } else {
      this.primaryForm.get('name').disable();
    }
  }

  public get siteConfig(): ISiteConfig {
    const {
      name,
      category,
      subcategory
    }: {
      name: string;
      category: string;
      subcategory: string;
    } = this.primaryForm.value;

    const type = this.typeCtrl.value;
    let base = { name };
    if (!this.isEditing) {
      base = noEmptyProps({
        type,
        distributionChannel: 'Direct',
        systemSiteIds: {
          sap: parseInt(this.accountNumber, 10)
        },
        ...base
      });
    }
    const { grossTonnage, personsOnBoard, ...sea } = this.secondaryForm.value;
    const sanitised = {
      ...sea,
      grossTonnage: grossTonnage !== null ? parseInt(grossTonnage, 10) : null,
      personsOnBoard: personsOnBoard !== null ? parseInt(personsOnBoard, 10) : null,
      vesselMarket: category,
      vesselSector: subcategory
    };

    switch (type) {
      case SiteCategory.Land:
        return {
          ...base,
          ...this.secondaryForm.value
        };
      case SiteCategory.Air:
        this.secondaryForm.controls.icaoNumber.enable();
        this.secondaryForm.controls.tailNumber.enable();
        this.getAircraftManufacturers();
        this.secondaryForm.value.sduValue === 'null'
          ? (this.secondaryForm.value.sduValue = 0)
          : (this.secondaryForm.value.sduValue = +this.secondaryForm.value.sduValue);

        return {
          ...base,
          ...this.secondaryForm.value,
          aircraftClass: category,
          aircraftManufacturer: this.secondaryForm.value.aircraftManufacturer
            ? this.aircraftManufacturers.find(
                (aircraftManufacturer) => aircraftManufacturer.value === this.secondaryForm.value.aircraftManufacturer
              )?.label
            : null
        };
      case SiteCategory.Sea:
        // Not using noEmptyProps as it blocks clearing optional values during edit
        return {
          ...base,
          ...sanitised
        };
      default:
        return null;
    }
  }

  private getAircraftManufacturers() {
    this.subs.add(
      this.store
        .select(getAircraftManufacturerOptions)
        .subscribe((aircraftManufacturer: { label: string; value: string }[]) => {
          this.aircraftManufacturers = aircraftManufacturer;
        })
    );
  }

  private configureCategoryControl(): void {
    this.categoryOptions$ = this.siteType$.pipe(
      observeOn(asyncScheduler),
      tap((type) => {
        this.updateWithType(type);
      }),
      switchMap((type: SiteCategory) =>
        type
          ? {
              Sea: this.siteForm.getVesselCategories(),
              Air: this.siteForm.getAircraftCategories(),
              Land: of(null)
            }[type]
          : of(null)
      ),
      tap((options) => {
        this.categoryCtrl.reset({
          disabled: options === null,
          value:
            options && options.some(({ value }) => value === this.categoryCtrl.value) ? this.categoryCtrl.value : null
        });
      }),
      share()
    );

    this.typeCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((type) => {
      this.siteType$.next(type);
    });
  }

  private updateWithType(type: SiteCategory, value?: any): void {
    if (type) {
      switch (type) {
        case SiteCategory.Sea: {
          this.categoryLabel = MARKET_LABEL;
          this.subcategoryLabel = SECTOR_LABEL;
          this.categoryPlaceholder = MARKET_PLACEHOLDER;
          this.subcategoryPlaceholder = SECTOR_PLACEHOLDER;
          this.secondaryQuestions = this.siteForm.getSeaQuestions();
          break;
        }
        case SiteCategory.Air: {
          this.categoryLabel = CLASS_LABEL;
          this.categoryPlaceholder = CLASS_PLACEHOLDER;
          this.secondaryQuestions = this.siteForm.getAirQuestions();
          break;
        }
        case SiteCategory.Land: {
          this.secondaryQuestions = this.siteForm.getLandQuestions();
          break;
        }
        default:
          // Something's gone wrong.
          this.secondaryQuestions = null;
          break;
      }

      this.secondaryForm = createFormGroup(flatten(this.secondaryQuestions?.map((s) => s.questions)));

      if (type === SiteCategory.Air) {
        this.onAircraftClassChange();
        this.onCountryChange();
        this.onIcaoNumberChange();
      }

      if (value) {
        this.secondaryForm.patchValue(value);
      }

      this.form.setControl('secondary', this.secondaryForm);
    } else {
      this.secondaryQuestions = null;
      this.form.removeControl('secondary');
    }
  }

  private addDefaultIcaoNumberValidators(): void {
    this.secondaryForm.controls.icaoNumber.enable();
    this.secondaryForm.controls.icaoNumber.clearValidators();
    this.secondaryForm.controls.icaoNumber.addValidators([Validators.pattern(ICAO_PATTERN)]);
    this.secondaryForm.controls.icaoNumber.updateValueAndValidity();
  }

  private addIcaoNumberValidators(): void {
    this.secondaryForm.controls.icaoNumber.enable();
    this.secondaryForm.controls.icaoNumber.clearValidators();
    this.secondaryForm.controls.icaoNumber.addValidators([Validators.pattern(ICAO_PATTERN)]);
    this.secondaryForm.controls.icaoNumber.updateValueAndValidity();

    this.secondaryForm.controls.tailNumber.clearValidators();
  }

  private addDefaultTailNumberValidators(): void {
    this.secondaryForm.controls.tailNumber.enable();
    this.secondaryForm.controls.tailNumber.clearValidators();
    this.secondaryForm.controls.tailNumber.addValidators(Validators.maxLength(20));
    this.secondaryForm.controls.tailNumber.updateValueAndValidity();
  }

  private addTailNumberValidators(): void {
    this.secondaryForm.controls.tailNumber.enable();
    this.secondaryForm.controls.tailNumber.addValidators([Validators.pattern(this.tailPrefix.label)]);
    if (this.secondaryForm.get('icaoNumber').valid && this.secondaryForm.get('icaoNumber').value) {
      this.secondaryForm.controls.tailNumber.addValidators([Validators.required]);
    }
    this.secondaryForm.controls.tailNumber.updateValueAndValidity();
  }

  private onCountryChange(): void {
    this.secondaryForm.controls.country.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        withLatestFrom(this.store.select(getICAOByCountryOptions), this.store.select(getTailNumberByCountryOptions))
      )
      .subscribe(([countrySelected, icaoByCountry, tailPrefixCountry]) => {
        this.filterCountriesForIcaoValidation(countrySelected, icaoByCountry, tailPrefixCountry);
      });
  }

  private onIcaoNumberChange(): void {
    this.secondaryForm.controls.icaoNumber.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((icaoNumber) => {
        if (icaoNumber && this.checkForAirCategory(this.airCategory)) {
          const data: string = icaoNumber;
          let binary = null;
          binary =
            data.length === 6
              ? parseInt(data, 16).toString(2).padStart(24, '0')
              : data.length === 8
                ? parseInt(data, 8).toString(2).padStart(24, '0')
                : icaoNumber;
          if (!binary.startsWith(this.icaoBinaryValidation.label)) {
            this.secondaryForm.controls.icaoNumber.setErrors({ pattern: true });
            this.secondaryForm.controls.tailNumber.removeValidators([Validators.required]);
          }
          if (this.secondaryForm.get('icaoNumber').valid) {
            this.secondaryForm.controls.tailNumber.addValidators([Validators.required]);
          }
        } else {
          const tailNumber = this.secondaryForm.controls.tailNumber.value;
          this.secondaryForm.controls.tailNumber.removeValidators([Validators.required]);
          this.secondaryForm.controls.tailNumber.setValue(tailNumber);
        }
      });
  }

  private filterCountriesForIcaoValidation(countrySelected: string, icaoByCountry, tailPrefixCountry): void {
    if (!this.checkForAirCategory(this.airCategory)) {
      this.addDefaultIcaoNumberValidators();
      this.addDefaultTailNumberValidators();
      this.countrySelected = countrySelected;

      return;
    }

    if (this.countrySelected !== countrySelected) {
      this.resetIcaoAndTailNumber();
    }

    this.icaoBinaryValidation = icaoByCountry.find((icaoObject) => countrySelected === icaoObject.value);
    this.tailPrefix = tailPrefixCountry.find((prefixObject) => countrySelected === prefixObject.value);

    if (this.icaoBinaryValidation && this.tailPrefix) {
      this.addIcaoNumberValidators();
      this.addTailNumberValidators();
    } else if (this.icaoBinaryValidation && !this.tailPrefix) {
      this.secondaryForm.get('icaoNumber').disable();
      this.addIcaoNumberValidators();
    } else if (!this.icaoBinaryValidation && this.tailPrefix) {
      this.addTailNumberValidators();
      this.secondaryForm.get('.tailNumber').disable();
    } else {
      this.resetIcaoAndTailNumber();

      this.secondaryForm.get('icaoNumber').removeValidators([Validators.required]);
      this.secondaryForm.get('tailNumber').removeValidators([Validators.required]);

      this.secondaryForm.get('icaoNumber').disable();
      this.secondaryForm.get('tailNumber').disable();
    }

    this.countrySelected = countrySelected;
  }

  private onAircraftClassChange(): void {
    this.categoryCtrl?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((category) => {
      if (category) {
        this.airCategory = category;
        if (!this.checkForAirCategory(this.airCategory)) {
          this.addDefaultIcaoNumberValidators();
          this.addDefaultTailNumberValidators();
        } else if (this.countrySelected) {
          this.secondaryForm.controls.country.setValue(this.countrySelected);
        }
      }
    });
  }

  private checkForAirCategory(airCategory: string): boolean {
    return !AIRCRAFT_CLASS_VALIDATION_EXCLUDE_LIST.includes(airCategory);
  }

  private resetIcaoAndTailNumber(): void {
    this.secondaryForm.get('icaoNumber').reset();
    this.secondaryForm.get('tailNumber').reset();
  }

  public ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
